<template>
    <div class="admin-index" >
        <div style="height: 800px; ">
            <!-- 搜索区 -->
                <el-form :inline="true" :model="find_form">
                        <el-form-item  label="姓名:">
                            <el-input v-model="find_form.name" placeholder="姓名" size="mini"></el-input>
                        </el-form-item>
                        <el-form-item  label="联系方式:" >
                            <el-input v-model="find_form.tel" placeholder="联系方式" size="mini"></el-input>
                        </el-form-item>
                        <el-form-item  label="状态:">
                            <el-select size="mini" v-model="find_form.state" clearable  placeholder="请选择状态">
                                <el-option label="所有" value=""></el-option>
                                <el-option label="白名单" value="2"></el-option>
                                <el-option label="黑名单" value="3"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item  label="必中奖项:">
                            <el-select size="mini" v-model="find_form.winning_award" clearable  placeholder="请选择必中奖项">
                                <el-option label="无" value="0"></el-option>
                                <el-option label="特等奖" value="1"></el-option>
                                <el-option label="一等奖" value="2"></el-option>
                                <el-option label="二等奖" value="3"></el-option>
                                <el-option label="三等奖" value="4"></el-option>
                                <el-option label="幸运奖" value="5"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="getDate()" size="mini" >查询</el-button>
                            <el-button type="info" size="mini" @click="resetForm()">重置</el-button>
                        </el-form-item>
                </el-form>
            <!-- 列表区 -->
            <el-table
                    :data="dataList"
                    border
                    v-loading="listLoading"
                    style="width: 100%"
                    id="printTable"
                >
                    <el-table-column prop="idNo" label="序号" width="70"></el-table-column>
                    <el-table-column prop="name" label="姓名"></el-table-column>
                    <el-table-column prop="tel" label="联系方式"></el-table-column>
                    <el-table-column prop="state" label="状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.state == 2"  style="color: #67C23A"> 白名单 </span>
                            <span v-if="scope.row.state == 3"  style="color: #909399"> 黑名单 </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="winning_award" label="必中奖项">
                        <template slot-scope="scope">
                            <span v-if="scope.row.winning_award == 1">特等奖</span>
                            <span v-if="scope.row.winning_award == 2">一等奖</span>
                            <span v-if="scope.row.winning_award == 3">二等奖</span>
                            <span v-if="scope.row.winning_award == 4">三等奖</span>
                            <span v-if="scope.row.winning_award == 5">幸运奖</span>
                            <span v-if="scope.row.winning_award == 0">无</span>
                        </template>
                    </el-table-column>
                    <el-table-column  label="操作" width="250px">
                        <template slot-scope="scope">
                            <el-button @click="oppenDialog(scope.row.id)" size="mini" >编辑</el-button>
                            <!-- <el-button v-if="scope.row.state != 2" @click="editLotteryUserFun(scope.row,2)" size="mini" >加入白名单</el-button>
                            <el-button v-if="scope.row.state != 3" @click="editLotteryUserFun(scope.row,3)" size="mini" type="info">加入黑名单</el-button> -->
                        </template>
                    </el-table-column>
                
                </el-table>

                <!-- 分页 -->
                <div class="Pagination" style="text-align: right; margin-top: 10px">
                    <el-pagination
                        @current-change="getDate()"
                        :current-page.sync="pageNum"
                        :page-size="pageSize"
                        layout="total, prev, pager, next"
                        :total="total"
                    ></el-pagination>
                </div>



        </div>

        <el-dialog
            title="编辑"
            :visible.sync="dialogVisible"
            width="40%"
            :before-close="handleClose">
            
                <el-form :model="user_from" label-width="20%">
                    <el-form-item  label="所在名单:">
                        <el-select size="mini" v-model="user_from.state" clearable  placeholder="请选择状态">
                            <el-option label="白名单" value="2"></el-option>
                            <el-option label="黑名单" value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item  label="必中奖项:">
                        <el-select size="mini" v-model="user_from.winning_award" clearable  placeholder="请选择状态">
                            <el-option label="全部" value="0"></el-option>
                            <el-option label="无" value="0"></el-option>
                            <el-option label="特等奖" value="1"></el-option>
                            <el-option label="一等奖" value="2"></el-option>
                            <el-option label="二等奖" value="3"></el-option>
                            <el-option label="三等奖" value="4"></el-option>
                            <el-option label="幸运奖" value="5"></el-option>
                            
                        </el-select>
                    </el-form-item>
                </el-form>
            
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click=" editSubmit()">确 定</el-button>
            </span>
         </el-dialog> 
    </div>
</template>

<script>
import { getAllLotteryUserPage , editLotteryUser} from "@/api/getBaseData";
export default {
    data() {
        return {
            pageNum:1,
            pageSize:10,
            total:0,
            dataList:[],
            listLoading:false,
            find_form:{
                state:"2"
            },
            user_from:{},
            dialogVisible:false
        }
    },
    mounted() {
        
        this.getDate();
    },
    methods: {
        doQuery() {
            
        },
        getDate() {

            let para = {
                pageNum:this.pageNum,
                pageSize:this.pageSize,
                name:this.find_form.name,
                tel:this.find_form.tel,
                state:this.find_form.state,
                winning_award:this.find_form.winning_award
            }
            getAllLotteryUserPage(para).then((res)=>{
                console.log("LLL",res);
                if(res.resultCode == 1){
                    for (var i = 0; i < res.data.list.length; i++) {
                        res.data.list[i].idNo =  (this.pageNum - 1) * this.pageSize + i + 1;
                    }
                    this.dataList = res.data.list;
                    if(res.data.pageNum != 0 ){
                        this.pageNum = res.data.pageNum;
                        this.pageSize = res.data.pageSize;
                    }else{
                        this.pageNum = 1;
                        this.pageSize = 10;
                    }
                    this.total = res.data.total;
                }else{
                    this.$message({showClose: true, message: res.resultMsg,type: 'error'});
                }
            })
        },

        editLotteryUserFun(row,state){
            
            editLotteryUser({id:row.id,state:state}).then((res)=>{
                if(res.resultCode == 1){
                    this.$message({message: '修改成功',type: 'success'});
                }else{
                    this.$message({message: res.resultMsg,type: 'error'});
                }
                
            });

        },
        oppenDialog(paramId){
            this.user_from = {};
            this.dialogVisible = true ;
            getAllLotteryUserPage({id:paramId,type:"list"}).then((res)=>{
                console.log("{{{{",res.data)
                this.user_from = res.data[0];
                this.user_from.state = this.user_from.state + "";
                this.user_from.winning_award = this.user_from.winning_award + "";
            });
            
        },
        editSubmit(){

            console.log("this.user_from",this.user_from);
            let para = {
                id:this.user_from.id,
                state:this.user_from.state,
                winning_award:this.user_from.winning_award
            }
            editLotteryUser(para).then((res)=>{
                this.getDate();

            })

            this.user_from = {};
            this.dialogVisible = false ;
            
        },
        handleClose(){
            this.user_from = {};
            this.dialogVisible = false ;
        },
        resetForm(){
            this.find_form = {}
        }
    }
}
</script>

<style lang="scss">
.admin-index {
    .el-table thead {
        color: #21293b;
        font-size: 0.8rem;
    }
    .el-table th,
    .el-table thead {
        background-color: #e9ecee;
        height: 3rem;
    }
    .el-table {
        font-size: 0.8rem;
    }
    .el-table td, .el-table th {
    text-align: center;
} 
}
</style>